import React, { useCallback, useEffect, useState } from "react";
import {
  SimpleForm,
  TextInput,
  NumberInput,
  AutocompleteInput,
  DateInput,
  ArrayInput,
  Button,
  SimpleFormIterator,
  required,
  useMutation,
  useRedirect,
  useRefresh,
  useNotify,
  FormDataConsumer,
  SelectInput
} from 'react-admin'
import { useForm } from 'react-final-form'
import * as XLSX from 'xlsx'
import {
  Divider,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Tooltip
} from "@material-ui/core"
import { planServices } from "../../../services/plan";
import { userServices } from "../../../services/user";
import { loginService } from "../../../services/login";
import SanitizedGrid from "../utils/sanitizedGrid";
import { FormToolbar } from "../../../utils/FormToolbar"
import { validateAge, validateDate, validateEmail, formatDate } from '../../../utils/helpers';
import InputMask from "react-input-mask";
import PlansCheckboxGroup from "../utils/PlansCheckboxGroup";
import { Publish, HelpOutline } from '@material-ui/icons';
import DownloadIcon from '@material-ui/icons/GetApp';
import csvtojson from 'csvtojson';
import { DownloadEmployeeTemplate } from '../../../utils/GetEmployeeTemplate';
import { checkLevel } from "../utils/employeeHelper";
import { companyServices } from "../../../services/company";
import CompanyDetails from "./components/CompanyDetails";
import ListOrCreateCompanyRadioGroup from "./components/ListOrCreateCompanyRadioGroup";
import GroupLevel from "../modals/GroupLevel";
import moment from "moment";

/**
* @typedef {import("../../../types/models").Company} Company
* @typedef {import("./components/ListOrCreateCompanyRadioGroup").ListOrCreate} ListOrCreate
*/

const useStyles = makeStyles({
  employeeActions: {
    display: 'flex',
    padding: '0px 10px',
    justifyContent: 'end'
  },
  employeeLoading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  "@keyframes loading": {
    "0%": { opacity: 0.2 },
    "20%": { opacity: 1 },
    "100%": { opacity: 0.2 }
  },
  loading: {
    "& span": {
      animationName: "$loading",
      animationDuration: "1s",
      animationIterationCount: "infinite",
    },
    "& p": {
      textAlign: 'center',
    },
    "& span:nth-child(2)": {
      animationDelay: "0.2s",
    },
    "& span:nth-child(3)": {
      animationDelay: "0.4s",
    }
  }
});

const ProposalView = props => {
  const [me, setMe] = useState(null);

  const { setPhone, selectedPlans, setSelectedPlans } = props;
  const [listOrCreateCompany, setListOrCreateCompany] = useState(/** @type {ListOrCreate} */('list'));
  const [companies, setCompanies] = useState(/** @type {Company[]} */([]));
  const [selectedCompany, setSelectedCompany] = useState(/** @type {Company} */(undefined));
  const [plans, setPlans] = useState([]);
  const [users, setUsers] = useState([]);
  const [effectiveDate, setEffectiveDate] = useState(null);
  const [groupLevel, setGroupLevel] = useState('m2');
  const [openAlertLevelGroupChange, setOpenAlertLevelGroupChange] = useState(false)
  const [loadingEmployee, setLoadingEmployee] = useState(false)
  const classes = useStyles();
  const form = useForm();

  // fetch companies for list
  useEffect(() => {
    async function fetchData() {
      const _companies = await companyServices.getAll();
      setCompanies(_companies);
      const selectedCompanyId = props.record?.company?.id;
      if (selectedCompanyId) {
        const _selectedCompany = _companies.find(x => x.id === selectedCompanyId);
        setSelectedCompany(_selectedCompany);
      }
    }
    fetchData();
  }, [props.record]);

  useEffect(() => {
    async function fetchData() {
      setUsers(await userServices.getAll());
      setMe(await loginService.me(localStorage.getItem('token')));
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (props.record?.plans && props.record?.effectiveDate) {
        setSelectedPlans(props.record.plans);
        setEffectiveDate(moment(props.record.effectiveDate).toDate());
      }
    }

    fetchData();
  }, [props.record.plans, props.record.effectiveDate, setSelectedPlans]);

  useEffect(() => {
    async function fetchData() {
      if (effectiveDate) {
        const validPlans = await planServices.getValidPlans(effectiveDate, groupLevel)
        setPlans(validPlans)
      }
    }

    fetchData();
  }, [effectiveDate, groupLevel]);

  useEffect(() => {
    if (listOrCreateCompany === 'create') {
      setSelectedCompany(null)
      form.change('company.id', null);
      form.change('company.name', null);
      form.change('company.address', null);
      form.change('company.city', null);
      form.change('company.zip', null);
      form.change('company.contactEmail', null);
      form.change('company.contactName', null);
    }
  }, [listOrCreateCompany, form]);

  const handleCompanyChange = useCallback((/** @type {number | string | undefined} */value) => {
    if (typeof value === "number") {
      const selectedValue = companies.find(company => company.id === value);
      setSelectedCompany(selectedValue);
      form.change('company', selectedValue);
    }
  }, [companies, form]);


  const importEmployees = (files) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      setLoadingEmployee(true)
      let readerResult = /** @type {string} */ (reader.result);

      if (files[0].type?.includes('excel') || files[0].type?.includes('sheet')) {
        try {
          const wb = XLSX.read(reader.result, { type: 'binary', dateNF: 'mm/dd/yyyy' });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          readerResult = XLSX.utils.sheet_to_csv(ws, /** @type {any} */({ header: 1 }))
        } catch (err) {
          setLoadingEmployee(false)
          if(err.message === "ECMA-376 Encrypted file missing /EncryptionInfo")
            alert("It looks like your file is encrypted. We're unable to format encrypted files. Please upload a non-encrypted file and try again.")
        }
      }

      csvtojson()
        .fromString(readerResult)
        .then((jsonObj) => {
          try {
            let index = -1;
            let childIndex = -1;
            const newEmployee = [];

            const allowedRelationships = ['Employee', 'Spouse', 'Child']
            if(jsonObj.some((item) => !allowedRelationships.includes(item.Relationship))) {
              setLoadingEmployee(false)
              return alert(`Invalid relationships. Must be 'Employee', 'Spouse', or 'Child'. Please check and try again.`)
            }

            for (let i = 0; i < jsonObj.length; i += 1) {
              if (jsonObj[i].Relationship === 'Employee') {
                index++;
                childIndex = -1;
                newEmployee[index] = {
                  children: [],
                  name: '',
                  zip: '',
                  birthdate: null,
                  age: null,
                  spouseAge: null,
                  spouseBirthdate: null
                };

                newEmployee[index].age = jsonObj[i].Age || null;
                newEmployee[index].zip = jsonObj[i]['Zip Code'];
                
                if (!jsonObj[i].Name) {
                  if (jsonObj[i].FirstName && jsonObj[i].LastName) {
                    newEmployee[index].name = `${jsonObj[i].FirstName} ${jsonObj[i].LastName}`;
                  }
                } else {
                  newEmployee[index].name = jsonObj[i].Name;
                }


                newEmployee[index].birthdate = jsonObj[i].DOB || null;
                newEmployee[index].age = jsonObj[i].Age || null;
              } else if (jsonObj[i].Relationship === 'Spouse') {
                newEmployee[index].spouseAge = jsonObj[i].Age || null;
                newEmployee[index].spouseBirthdate = jsonObj[i].DOB || null;
              } else if (jsonObj[i].Relationship === 'Child') {
                childIndex++;
                newEmployee[index].children[childIndex] = {
                  age: jsonObj[i].Age || null,
                  birthdate: jsonObj[i].DOB || null
                };
              }
            }
            form.change('employeeCensus', newEmployee)
          } catch (err) {
            setLoadingEmployee(false)
            alert(`
              It looks like there are some invalid items. 
              Here are the valid items and their formats:

              - Name: Should be a string
              - Relationship: Should be either 'Employee', 'Spouse', or 'Child'
              - DOB: Should be in the format YYYY/MM/DD or YYYY-MM-DD or MM/DD/YYYY
              - Age: Should be a number
              - Zip Code: Should be a number

              Please check your file and try again.
            `)
            throw new Error('Invalid items')
          }
        }).finally(() => {
          setLoadingEmployee(false)
        })
    }
    reader.readAsBinaryString(files[0]);
  }

  const switchLevelGroup = ({ groupLevel, employeeCensus }) => {
    form.change('groupLevel', 'm1')
    if (groupLevel === 'm1' && employeeCensus?.length > 1) {
      form.change('employeeCensus', [employeeCensus[0]]);
    }
    setOpenAlertLevelGroupChange(false)
  }

  const onChangeGroupLevel = (formData) => {
    setGroupLevel(form.getState().values.groupLevel)
    formData.groupLevel === 'm2'
      && formData.employeeCensus?.length > 1
      && setOpenAlertLevelGroupChange(true)
  }

  const onCancelSwitchLevelGroup = () => {
    form.change('groupLevel', 'm2')
    setOpenAlertLevelGroupChange(false)
  }

  return <>
    <GroupLevel
      openAlertLevelGroupChange={openAlertLevelGroupChange}
      setOpenAlertLevelGroupChange={setOpenAlertLevelGroupChange}
      onOk={switchLevelGroup}
      onCancel={onCancelSwitchLevelGroup}
    />
    <SanitizedGrid container spacing={10}>
      <Grid item xs={5} style={{ paddingRight: 65 }}>
        <ListOrCreateCompanyRadioGroup value={listOrCreateCompany} onChange={setListOrCreateCompany} />
        {listOrCreateCompany === 'list' ? (<>
          <AutocompleteInput
            optionText={value => value?.name}
            source={'company.id'}
            label={'Company'}
            choices={companies}
            onInputValueChange={handleCompanyChange}
            fullWidth
          />
          <CompanyDetails company={selectedCompany} />
        </>) : (<>
          <TextInput source={'company.name'} label={'Company name'} fullWidth validate={[required()]} />
          <TextInput source={'company.address'} label={'Address'} fullWidth validate={[required()]} />
          <TextInput source={'company.city'} label={'City'} fullWidth validate={[required()]} />
          <TextInput type={'number'} source={'company.zip'} label={'Zip'} validate={[required()]} />
          <TextInput source={'company.contactName'} label={'Contact name'} fullWidth />
          <TextInput source={'company.contactEmail'} label={'Email'} fullWidth />
          <InputMask
            mask="999-999-9999"
            disabled={false}
            {...{ maskChar: " " }}
            onChange={e => setPhone(e.target.value?.replace(/[^0-9]/g, ''))}
          >
            {/* @ts-ignore */}
            {() => <TextField id="company.contactPhone" variant="filled" label={'Phone'} />}
          </InputMask>
        </>)}
      </Grid>
      <Grid item xs={4}>
        <AutocompleteInput
          optionText={value => value?.fullName}
          source={'userId'}
          label={'Sales Representative'}
          choices={users}
          disabled={!me || me.type === "sales"}
          defaultValue={me?.id}
          fullWidth
        />
        <TextInput source={'brokerName'} fullWidth />
        <TextInput source={'agency'} fullWidth />
        <FormDataConsumer>
          {({ formData }) => (
            <SelectInput
              source={'groupLevel'}
              defaultValue={'m2'}
              onChange={() => onChangeGroupLevel(formData)}
              fullWidth
              choices={[
                { id: 'm1', name: 'M1' },
                { id: 'm2', name: 'M2' },
              ]}
            />
          )}
        </FormDataConsumer>
        <DateInput source={'effectiveDate'} defaultValue={null} autoComplete={'off'} validate={[required()]}
          format={formatDate}
          onChange={(e) => {
            setSelectedPlans([])
            setEffectiveDate(moment(e.target.value).toDate())
          }
          } />
        {
          effectiveDate &&
          <PlansCheckboxGroup
            key={effectiveDate}
            plans={plans}
            selected={selectedPlans?.map((item) => item?.id)}
            onChange={setSelectedPlans}
            effectiveDate={effectiveDate}
            fullWidth
          />
        }
      </Grid>
      <Grid item xs={12}>
        <Divider />
        {loadingEmployee ?
          <div className={classes.loading}>
            <p>
              Importing employees<span>.</span><span>.</span><span>.</span>
            </p>
          </div>
        :
        <>
          <div className={classes.employeeActions}>
            <Tooltip title="Must be a '.csv', '.tsv', '.xls' or '.xlsx' file">
              <div>
                <input
                  accept=".csv,.tsv,.xls, .xlsx"
                  style={{ display: 'none' }}
                  onChange={(e) => importEmployees(e.target.files)}
                  id="raised-button-file"
                  type="file"
                />
                <label htmlFor="raised-button-file">
                  <Button color="primary" component="span" label="Import">
                    <Publish />
                  </Button>
                </label>
              </div>
            </Tooltip>
            <Button
              color="primary"
              component="span"
              label="Download Template"
              onClick={() => DownloadEmployeeTemplate()}
            >
              <DownloadIcon />
            </Button>
          </div>
          <ArrayInput source={'employeeCensus'} id={'employeeCensus'} label={'Employee census'} validate={checkLevel}>
            <FormDataConsumer>
              {({ formData }) =>
                <SimpleFormIterator TransitionProps={{ enter: false }} disableAdd={formData.groupLevel === 'm1' && formData.employeeCensus?.length === 1}>
                  <TextInput source={'name'} label={'Full name'} fullWidth validate={[required()]} />
                  <TextInput type={'number'} source={'zip'} label={'ZIP'} validate={[required()]} />
                  <FormDataConsumer>
                    {({
                      scopedFormData,
                      getSource,
                      ...rest
                    }) =>
                      <DateInput
                        source={getSource('birthdate')}
                        {...rest}
                        format={formatDate}
                        validate={[validateDate]}
                        label={'Birthdate'}
                        disabled={!!scopedFormData?.age}
                        autoComplete="off"
                      />
                    }
                  </FormDataConsumer>
                  <span> or </span>
                  <FormDataConsumer>
                    {({
                      scopedFormData,
                      getSource,
                      ...rest
                    }) =>
                      <NumberInput
                        source={getSource('age')}
                        {...rest}
                        style={{ width: 100 }}
                        label={'Age'}
                        validate={[validateAge]}
                        disabled={!!scopedFormData?.birthdate}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title='If entering age, state age at the time of the effective date.'>
                                <HelpOutline />
                              </Tooltip>
                            </InputAdornment>
                          )
                        }}
                      />
                    }
                  </FormDataConsumer>
                  <br />
                  <FormDataConsumer>
                    {({
                      scopedFormData,
                      getSource,
                      ...rest
                    }) =>
                      <DateInput
                        source={getSource('spouseBirthdate')}
                        {...rest}
                        format={formatDate}
                        validate={[validateDate]}
                        label={'Spouse birthdate'}
                        disabled={!!scopedFormData?.spouseAge}
                        autoComplete="off"
                      />
                    }
                  </FormDataConsumer>
                  <span> or </span>
                  <FormDataConsumer>
                    {({
                      scopedFormData,
                      getSource,
                      ...rest
                    }) =>
                      <NumberInput
                        source={getSource('spouseAge')}
                        {...rest}
                        validate={[validateAge]}
                        style={{ width: 140 }}
                        label={'Spouse age'}
                        disabled={!!scopedFormData?.spouseBirthdate}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title='If entering age, state age at the time of the effective date.'>
                                <HelpOutline />
                              </Tooltip>
                            </InputAdornment>
                          )
                        }}
                      />
                    }
                  </FormDataConsumer>
                  <ArrayInput source={'children'} label={'Children'} id={'children'}>
                    <SimpleFormIterator>
                      <FormDataConsumer>
                        {({
                          scopedFormData,
                          getSource,
                          ...rest
                        }) =>
                          <DateInput
                            format={formatDate}
                            source={getSource('birthdate')}
                            {...rest}
                            validate={[validateDate]}
                            label={'Child birthdate'}
                            disabled={!!scopedFormData?.age}
                            autoComplete="off"
                          />
                        }
                      </FormDataConsumer>
                      <span> or </span>
                      <FormDataConsumer>
                        {({
                          scopedFormData,
                          getSource,
                          ...rest
                        }) =>
                          <NumberInput
                            source={getSource('age')}
                            {...rest}
                            validate={[validateAge]}
                            style={{ width: 120 }}
                            label={'Child age'}
                            disabled={!!scopedFormData?.birthdate}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Tooltip title='If entering age, state age at the time of the effective date.'>
                                    <HelpOutline />
                                  </Tooltip>
                                </InputAdornment>
                              )
                            }}
                          />
                        }
                      </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>
                </SimpleFormIterator>
              }
            </FormDataConsumer>
          </ArrayInput>
        </>
        }

      </Grid>
    </SanitizedGrid>
  </>
}

const Wrapper = props => {

  const [mutate] = useMutation();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const notify = useNotify();
  const [selectedPlans, setSelectedPlans] = useState(props.record.plans || []);
  const [phone, setPhone] = useState('');

  const validate = (values) => {
    const errors = {
      company: {
        contactEmail: undefined,
        contactName: undefined
      },
      employeeCensus: []
    };

    for (let i = 0; i < values.employeeCensus?.length; i += 1) {
      errors.employeeCensus[i] = {
        age: undefined,
        birthdate: undefined,
        children: []
      }

      if (!values.employeeCensus[i]?.birthdate && !values.employeeCensus[i]?.age) {
        errors.employeeCensus[i].age = 'At last one is required';
        errors.employeeCensus[i].birthdate = 'At last one is required';
      }

      for (let j = 0; j < values.employeeCensus[i]?.children?.length; j += 1) {
        if (!values.employeeCensus[i]?.children[j]?.birthdate && !values.employeeCensus[i]?.children[j]?.age) {
          errors.employeeCensus[i].children[j] = {
            age: 'At last one is required',
            birthdate: 'At last one is required'
          }
        }
      }
    }

    if (values.company?.contactEmail) {
      errors.company.contactEmail = validateEmail(values.company.contactEmail);
    }

    if (values.company?.contactName) {
      const contactNameArray = values.company.contactName.split(' ');
      errors.company.contactName = contactNameArray.length <= 1
        ? 'Insert a name and last name'
        : contactNameArray[contactNameArray.length - 1] === ''
          ? 'Insert a name and last name'
          : undefined;
    }

    if (!selectedPlans.length)
      errors.plans = 'You should select a least one plan'

    return errors;
  }

  const onSuccess = useCallback(
    () => {
      notify('Element created');
      redirect('/proposal');
      refresh();
    },
    [notify, redirect, refresh]
  );

  const handleSave = useCallback(
    async (values) => {
      try {
        values.plans = selectedPlans.map(plan => plan.id || plan);
        if (!!phone) {
          values.company.contactPhone = phone;
        }
        if (values.company.id === null) {
          delete values.company.id;
        }
        delete values.updatedBy;
        delete values.updatedById;
        await mutate({
          type: 'create',
          resource: 'proposal',
          payload: { data: values },
        }, { returnPromise: true, onSuccess });
      } catch (error) {
        return error.body ? error.body.errors : error.message;
      }
    },
    [mutate, phone, onSuccess, selectedPlans],
  );

  return <SimpleForm
    {...props}
    toolbar={<FormToolbar />}
    validate={validate}
    save={handleSave}
  >
    <ProposalView
      record={props.record}
      setSelectedPlans={setSelectedPlans}
      selectedPlans={selectedPlans}
      setPhone={setPhone}
    />
  </SimpleForm>

}

export default Wrapper;
